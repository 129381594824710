/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:8da2967f-de91-433c-b9e6-4d40a04341d1",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_H1yfNHNAs",
    "aws_user_pools_web_client_id": "m9jot1mk84bafdn51683vh0hg",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "webappf11c2e6af85a417ea4b31b2d60bd7cc4234204-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;

import React from 'react';

const IconRoleManager = () => {
  return (

    <>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="16" height="16" viewBox="0 0 496.000000 496.000000"
           preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,496.000000) scale(0.100000,-0.100000)"
           fill="currentColor" stroke="none">
          <path d="M2065 4928 c-8 -18 -29 -96 -45 -173 -17 -76 -32 -140 -33 -141 -1 0
-40 -11 -87 -24 -137 -36 -264 -87 -431 -172 l-155 -79 -139 85 c-76 48 -150
86 -162 86 -13 0 -36 -6 -51 -14 -46 -25 -500 -488 -507 -518 -11 -43 -2 -63
88 -203 l83 -131 -58 -104 c-76 -138 -150 -314 -190 -453 -17 -62 -32 -113
-33 -115 -1 -1 -64 -16 -141 -32 -76 -16 -154 -37 -172 -45 l-32 -15 0 -397 0
-398 22 -14 c13 -8 90 -29 172 -48 82 -18 150 -34 151 -36 1 -1 12 -40 25 -87
36 -135 94 -282 176 -440 l76 -146 -86 -139 c-86 -138 -96 -167 -75 -208 16
-31 469 -485 501 -503 15 -8 38 -14 50 -14 13 0 85 38 160 85 75 47 141 85
148 85 6 0 44 -19 83 -42 146 -85 384 -182 535 -218 28 -6 52 -13 52 -14 0
-20 70 -308 79 -324 l12 -22 399 0 399 0 12 22 c6 12 27 89 46 172 19 83 35
151 36 152 1 1 52 16 114 33 167 47 382 139 505 217 20 13 42 24 47 24 6 0 72
-38 147 -85 83 -51 148 -85 164 -85 15 0 45 13 66 29 46 34 454 445 478 481
29 44 19 71 -73 217 l-89 141 41 68 c90 154 190 398 227 552 6 28 14 52 16 52
22 0 287 64 312 75 l32 15 0 400 0 400 -32 15 c-18 8 -96 28 -173 45 -77 18
-140 32 -141 33 0 1 -13 47 -28 102 -46 164 -145 394 -222 516 -13 21 -24 43
-24 48 0 6 38 72 85 147 47 76 85 148 85 161 0 13 -6 36 -14 51 -16 29 -455
469 -496 496 -44 29 -71 19 -216 -72 l-140 -89 -70 40 c-160 92 -447 209 -566
231 -21 4 -38 8 -38 10 0 24 -71 307 -81 324 l-14 22 -398 0 -397 0 -15 -32z
m681 -307 c39 -176 51 -192 158 -216 211 -48 458 -150 635 -265 93 -60 113
-57 267 40 108 69 129 79 143 68 19 -15 255 -252 289 -290 l23 -26 -80 -129
c-58 -92 -81 -139 -81 -162 0 -21 20 -65 62 -134 106 -176 186 -369 233 -564
37 -152 41 -155 226 -196 l144 -32 0 -234 0 -234 -120 -28 c-66 -16 -128 -29
-137 -29 -10 0 -33 -14 -51 -30 -30 -26 -38 -44 -57 -122 -50 -208 -128 -394
-246 -594 -75 -125 -74 -132 27 -291 l80 -127 -164 -164 -164 -164 -129 81
c-94 59 -137 81 -161 81 -23 0 -65 -19 -136 -62 -181 -109 -402 -199 -598
-242 -112 -25 -123 -39 -163 -217 l-32 -144 -234 0 -235 0 -31 144 c-41 181
-48 190 -178 221 -205 50 -412 137 -606 254 -78 47 -99 56 -124 50 -16 -3 -85
-42 -153 -86 l-125 -79 -41 39 c-58 54 -259 255 -275 274 -11 14 -1 35 68 145
57 92 80 139 80 162 0 21 -20 65 -62 134 -109 181 -200 406 -242 598 -25 112
-39 123 -217 163 l-144 31 0 235 0 234 140 31 c150 34 179 45 194 73 5 9 20
63 34 118 50 201 130 391 246 584 72 120 71 130 -29 286 -44 69 -80 127 -80
129 0 5 320 325 326 325 2 0 62 -36 132 -80 93 -58 138 -80 161 -80 23 0 65
19 135 62 177 108 381 191 582 239 139 32 136 28 179 224 14 66 28 126 30 133
4 10 56 12 237 10 l233 -3 31 -144z"/>
          <path d="M2325 4020 c-231 -23 -487 -112 -679 -235 -126 -80 -280 -223 -385
-355 -84 -106 -108 -144 -164 -256 -276 -554 -200 -1211 194 -1681 473 -563
1247 -721 1899 -387 271 138 533 402 670 674 293 582 195 1276 -245 1748 -236
252 -516 409 -843 472 -114 22 -333 32 -447 20z m376 -226 c182 -30 379 -110
539 -217 96 -64 273 -241 337 -337 155 -231 233 -486 233 -760 0 -217 -49
-424 -145 -610 -48 -92 -133 -222 -150 -227 -6 -2 -21 18 -35 44 -40 76 -136
166 -223 207 -85 41 -166 55 -324 56 l-103 0 0 28 c0 20 18 46 69 99 45 46 81
96 103 141 l33 69 77 6 c118 10 192 54 242 148 24 42 29 64 29 118 0 101 -48
181 -139 232 -30 17 -54 34 -54 37 0 4 10 36 21 72 18 56 20 79 14 168 -11
176 -56 282 -165 392 -114 113 -235 162 -400 162 -112 -1 -205 -27 -285 -81
-25 -17 -49 -31 -52 -31 -3 0 -24 16 -46 36 -60 55 -117 76 -203 76 -157 0
-274 -98 -320 -269 -23 -87 -20 -293 6 -422 11 -57 20 -104 18 -106 -2 -1 -27
-15 -56 -31 -95 -50 -145 -131 -145 -234 0 -75 23 -129 78 -185 53 -53 105
-75 193 -82 l77 -6 33 -69 c22 -45 58 -95 103 -141 51 -53 69 -79 69 -99 l0
-28 -102 0 c-159 -1 -240 -15 -325 -56 -87 -41 -183 -131 -223 -207 -14 -26
-29 -46 -35 -44 -17 5 -102 135 -150 227 -96 186 -145 393 -145 610 0 274 78
529 233 760 65 97 241 273 340 339 155 104 353 184 532 215 111 20 330 20 446
0z m-510 -459 c113 -106 139 -109 234 -26 89 78 133 96 240 96 82 0 96 -3 157
-33 75 -36 123 -88 160 -168 19 -41 23 -66 23 -156 0 -64 -4 -111 -11 -118 -8
-8 -56 25 -175 121 -241 195 -245 197 -309 164 -35 -18 -55 -54 -63 -112 -7
-57 -40 -107 -84 -128 -35 -17 -369 -72 -378 -63 -16 15 -37 204 -32 288 8
151 42 209 125 210 27 0 46 -13 113 -75z m503 -470 c23 -25 49 -45 57 -45 9 0
34 -21 57 -47 l42 -46 0 -152 c0 -83 -4 -155 -9 -161 -6 -5 -17 -35 -25 -67
-26 -100 -104 -177 -204 -203 -31 -8 -61 -19 -67 -25 -12 -12 -110 -12 -133 0
-9 6 -34 13 -55 16 -22 4 -50 18 -67 35 -17 17 -36 30 -42 30 -18 0 -58 42
-58 60 0 9 -9 25 -21 36 -41 37 -51 81 -56 251 l-6 161 64 4 c99 6 279 47 326
74 23 14 62 47 85 74 24 28 49 50 57 50 8 0 33 -20 55 -45z m-804 -305 c0 -49
-1 -50 -31 -50 -45 0 -69 16 -69 48 0 38 17 52 62 52 l38 0 0 -50z m1268 38
c7 -7 12 -24 12 -38 0 -36 -17 -50 -62 -50 l-38 0 0 50 0 50 38 0 c21 0 43 -5
50 -12z m-667 -700 l119 1 0 -46 c0 -88 -51 -143 -133 -143 -40 0 -96 35 -114
70 -16 32 -18 134 -2 124 6 -3 64 -6 130 -6z m-323 -211 c34 -64 103 -131 169
-163 50 -25 69 -29 143 -29 66 1 96 6 135 23 60 27 156 120 181 173 l17 39
138 0 c166 0 227 -17 294 -85 40 -40 85 -131 85 -174 0 -23 -131 -118 -244
-175 -96 -49 -232 -98 -341 -121 -117 -26 -343 -31 -465 -11 -134 23 -291 73
-403 130 -119 61 -247 152 -247 176 0 44 44 135 85 175 67 68 123 84 290 84
l140 1 23 -43z"/>
        </g>
      </svg>

    </>
  )
};

export default IconRoleManager;

import React from 'react';

const IconTransaction = () => {
  return (

    <>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="16" height="16" viewBox="0 0 200.000000 200.000000"
           preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
           fill="currentColor" stroke="none">
          <path d="M896 1960 c-20 -22 -36 -42 -36 -45 0 -2 13 -20 30 -38 l30 -34 -70
            -12 c-147 -24 -310 -102 -427 -206 -185 -163 -305 -472 -273 -702 18 -134 64
            -271 116 -351 l15 -23 37 22 c75 45 75 44 42 110 -100 198 -98 452 6 650 95
            180 285 322 486 363 42 9 81 16 85 16 4 0 -13 -20 -37 -45 l-44 -45 47 -47 47
            -48 115 115 c63 63 115 122 115 130 0 20 -211 230 -231 230 -9 0 -33 -18 -53
            -40z"/>
          <path d="M1437 1648 l-36 -53 66 -60 c37 -33 70 -60 74 -60 5 0 29 17 54 38
            38 33 44 41 35 58 -11 19 -138 129 -150 129 -4 0 -23 -24 -43 -52z"/>
          <path d="M910 1470 c-99 -17 -211 -79 -274 -151 -53 -61 -103 -170 -118 -256
            -31 -185 81 -401 252 -487 279 -139 606 9 685 309 68 265 -114 540 -386 585
            -71 11 -94 11 -159 0z m130 -165 c0 -30 4 -35 28 -41 38 -8 100 -35 111 -48 6
            -7 -1 -23 -19 -44 -28 -33 -28 -33 -59 -18 -99 51 -235 12 -199 -57 13 -25 68
            -47 115 -47 134 0 217 -80 197 -188 -10 -52 -67 -108 -127 -123 -44 -11 -47
            -13 -47 -45 l0 -34 -50 0 -50 0 0 34 c0 33 -2 35 -52 50 -29 8 -68 28 -87 44
            l-35 29 28 36 27 35 50 -26 c52 -26 138 -40 183 -28 32 8 57 53 45 83 -9 24
            -47 37 -139 48 -107 12 -174 70 -172 151 0 61 23 92 95 128 52 26 57 31 57 62
            l0 34 50 0 50 0 0 -35z"/>
          <path d="M1679 1427 c-73 -44 -72 -42 -39 -107 124 -246 88 -566 -87 -771
            -118 -139 -268 -222 -458 -254 l-40 -7 45 46 44 46 -47 47 -47 48 -115 -115
            c-63 -63 -115 -122 -115 -130 0 -20 211 -230 231 -230 16 0 89 70 89 85 0 5
            -14 23 -30 40 l-31 32 70 12 c149 24 317 105 430 207 185 166 303 471 271 703
            -6 43 -20 111 -31 151 -19 68 -80 203 -96 213 -5 3 -24 -4 -44 -16z"/>
          <path d="M406 487 l-48 -43 25 -30 c31 -37 135 -119 142 -112 3 3 21 27 40 54
            l34 48 -67 63 c-37 34 -69 62 -72 62 -3 0 -27 -19 -54 -42z"/>
        </g>
      </svg>
    </>
  )
};

export default IconTransaction;


export const permissions = {

  FIND_JOB: 'FIND_JOB',
  VIEW_JOB: 'VIEW_JOB',
  UPDATE_JOB: 'UPDATE_JOB',
  CREATE_JOB: 'CREATE_JOB',
  CANCEL_JOB: 'CANCEL_JOB',
  DELETE_JOB: 'DELETE_JOB',
  // PARTIAL_UPDATE_JOB: 'PARTIAL_UPDATE_JOB',
  UPDATE_STATUS_JOB: 'UPDATE_STATUS_JOB',
  ASSIGN_QC_JOB: 'ASSIGN_QC_JOB',
  ASSIGN_STAFF_JOB: 'ASSIGN_STAFF_JOB',

  VIEW_JOB_TYPE: 'VIEW_JOB_TYPE',
  CREATE_JOB_TYPE: 'CREATE_JOB_TYPE',
  UPDATE_JOB_TYPE: 'UPDATE_JOB_TYPE',
  DELETE_JOB_TYPE: 'DELETE_JOB_TYPE',
  // PARTIAL_UPDATE_JOB_TYPE: 'PARTIAL_UPDATE_JOB_TYPE',
  FIND_JOB_TYPE: 'FIND_JOB_TYPE',

  UPDATE_ROLE: 'UPDATE_ROLE',
  DELETE_ROLE: 'DELETE_ROLE',
  CREATE_ROLE: 'CREATE_ROLE',
  GET_ALL_ROLES: 'GET_ALL_ROLES',

  ADD_USER: 'ADD_USER',
  UPDATE_USER: 'UPDATE_USER',
  FIND_USER: 'FIND_USER',
  VIEW_USER: 'VIEW_USER',
  ACTIVE_INACTIVE_USER: 'ACTIVE_INACTIVE_USER',
  // PARTIAL_UPDATE_USER: 'PARTIAL_UPDATE_USER',

  GET_CUSTOMER_PRICES: 'GET_CUSTOMER_PRICES',
  CREATE_CUSTOMER_PRICES: 'CREATE_CUSTOMER_PRICES',
  PERMISSION_TEST: 'PERMISSION_TEST',

  FIND_TRANSACTION: 'FIND_TRANSACTION',
  FIND_CUSTOMER: 'FIND_CUSTOMER',
  FIND_WORKER: 'FIND_WORKER',


  CREATE_BROADCAST: 'CREATE_BROADCAST',
  DELETE_BROADCAST: 'DELETE_BROADCAST',
  UPDATE_BROADCAST: 'UPDATE_BROADCAST',
};
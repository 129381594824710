import React from 'react';

const IconBroadcasts = () => {
  return (

    <>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
           width="16" height="16" viewBox="0 0 512.000000 512.000000"
           preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
           fill="currentColor" stroke="none">
          <path d="M1036 4724 c-160 -39 -312 -161 -381 -305 -39 -83 -65 -187 -65 -266
          l0 -53 -62 0 c-82 0 -175 -23 -257 -62 -81 -38 -206 -158 -245 -235 l-26 -52
          0 -1511 0 -1511 25 -49 c36 -70 137 -174 215 -221 38 -22 100 -48 146 -59 79
          -20 103 -20 2204 -18 l2125 3 58 22 c133 51 267 165 322 273 l25 49 0 1514 0
          1513 -36 60 c-69 116 -185 211 -311 255 -33 12 -98 23 -150 26 l-93 6 0 51
          c-1 260 -188 503 -434 566 -78 20 -107 20 -1540 19 -1225 -1 -1470 -3 -1520
          -15z m3035 -326 c93 -48 149 -141 149 -249 l0 -47 -1661 0 -1662 0 6 63 c11
          120 58 192 151 236 l61 29 1446 0 1446 0 64 -32z m635 -637 c69 -32 105 -73
          135 -151 18 -49 19 -91 19 -1370 0 -1279 -1 -1321 -19 -1370 -29 -76 -64 -115
          -132 -149 l-62 -31 -2086 0 -2086 0 -60 29 c-72 33 -106 71 -134 146 -21 55
          -21 64 -21 1375 0 1279 1 1321 19 1370 30 78 66 119 135 151 l61 29 2085 0
          2085 0 61 -29z"/>
                    <path d="M650 3634 c-23 -21 -44 -51 -50 -72 -13 -49 -13 -675 0 -724 6 -21
          27 -51 50 -72 l40 -36 773 0 c2075 -1 2098 -1 2132 18 16 10 40 33 52 52 23
          33 23 36 23 399 0 348 -1 368 -20 399 -11 18 -34 41 -52 52 -33 20 -54 20
          -1470 20 l-1438 0 -40 -36z m2718 -436 l1 -169 -60 3 -59 3 0 44 c0 130 -145
          213 -243 138 -48 -37 -67 -75 -67 -135 l0 -50 -60 1 -60 0 0 49 c0 60 -19 99
          -67 135 -29 22 -46 28 -84 27 -89 -3 -146 -60 -156 -156 l-5 -53 -59 -3 c-59
          -2 -59 -2 -57 25 5 71 -2 94 -39 134 -25 26 -51 43 -75 48 -59 14 -108 -2
          -150 -48 -32 -34 -38 -47 -38 -85 0 -57 -19 -76 -74 -76 l-43 0 -6 55 c-6 60
          -35 117 -72 141 -14 9 -46 15 -80 15 -95 0 -155 -62 -155 -161 l0 -50 -60 0
          -60 0 0 54 c0 93 -60 157 -147 158 -93 0 -150 -55 -160 -156 l-6 -55 -163 0
          -164 0 0 169 0 168 1234 0 1233 0 1 -170z"/>
                    <path d="M4147 3659 c-111 -26 -251 -142 -306 -254 -54 -109 -59 -277 -13
          -385 28 -65 103 -160 156 -198 89 -64 151 -86 258 -90 124 -5 209 18 295 82
          99 73 158 158 188 273 61 235 -83 492 -317 565 -64 19 -193 23 -261 7z m187
          -314 c78 -37 109 -150 63 -229 -57 -100 -188 -101 -261 -3 -29 40 -29 135 0
          174 51 68 128 91 198 58z"/>
                    <path d="M684 2166 c-50 -22 -86 -77 -87 -133 -1 -61 22 -105 70 -135 l38 -23
          1729 -3 c1152 -1 1741 1 1766 8 23 6 52 26 74 50 32 35 36 46 36 93 0 57 -17
          91 -65 129 l-30 23 -1750 2 c-1459 2 -1755 0 -1781 -11z"/>
                    <path d="M649 1713 c-72 -67 -72 -159 0 -225 l40 -38 1758 0 c1737 0 1758 0
          1791 20 96 59 96 201 0 260 -33 20 -54 20 -1791 20 l-1758 0 -40 -37z"/>
                    <path d="M703 1319 c-42 -12 -90 -61 -102 -106 -13 -46 -4 -90 26 -131 50 -67
          -99 -62 1835 -60 l1753 3 30 23 c48 38 65 72 65 129 0 47 -4 58 -36 93 -21 23
          -51 44 -72 50 -48 13 -3453 12 -3499 -1z"/>
        </g>
      </svg>

    </>
  )
};

export default IconBroadcasts;
